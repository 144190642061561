import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import SEO from "components/seo";
import Layout from "components/layout";
import Contact from "components/contact";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
    pageContext
  }) {
    // console.log(pageContext)
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    useEffect(() => {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const Plyr = require('plyr')
        Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p))
      }
    });

    return (
      <Layout>
          <SEO
              pageData={{
                  title: frontmatter.title + `: HFSP`,
                  description: frontmatter.excerpt,
              }}
          />
          <div className="page mx-auto max-w-screen-xl">
            <div className="md:grid md:grid-cols-2 md:items-center bg-green-800">
              <h1 className="text-white text-center text-4xl font-extrabold py-16 md:py-0 px-2 sm:px-16">{frontmatter.title}</h1>
              <GatsbyImage image={frontmatter.featureImage.childImageSharp.gatsbyImageData} alt="" />
            </div>
                {frontmatter.imageUrl && frontmatter.imageCaption &&
                <div className="text-xs text-right mb-6 py-2 px-4">
                  Photo by <a href={frontmatter.imageUrl} target="_blank" rel="noopener noreferrer nofollow" className="underline">
                    {frontmatter.imageCaption}
                  </a>
                </div>
                }
            <div className="page mx-auto max-w-screen-md mb-12 px-4">
              <div className="text-sm">{frontmatter.date}</div>
              <div className="text-2xl font-medium mb-6" dangerouslySetInnerHTML={{ __html: frontmatter.excerpt }} />
              {frontmatter.video &&
              <div className="js-player" data-plyr-provider="youtube" data-plyr-embed-id={frontmatter.video}></div>
              }
              <div className="" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>

          <Contact />
      </Layout>
    );
}
export const pageQuery = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      excerpt
      video
      imageCaption
      imageUrl
      featureImage {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  }
}
`