import React from "react";
import { Link } from "gatsby";

export default function Contact() {
    return (
        <div className="page max-w-screen-xs mx-auto px-4 my-16 text-center">
            <span className="mb-4 inline-block text-3xl font-extrabold pb-1 border-b-4 border-green-700">Ready to start?</span>
            <p>Are you ready to get your finances back on track? Contact us now for a no-obligation chat about how we can help you.</p>
            <Link to="/contact/" className="inline-block rounded py-2 px-8 bg-green-700 text-white font-semibold">Get in touch</Link>
        </div>
    );
}
